import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useReducer } from 'react';
import {
  message,
  Button,
  Dropdown,
  Menu,
  Select,
  Typography,
  Space,
} from 'antd';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  defaultState,
  CustomActionsBarReducer,
} from './CustomActionBarReducer';
import CreateRouteModal from '../../retail/dashboard/CreateRouteModal';
import {
  closeAllModals,
  setActionConfig,
  switchModalVisibility,
} from './CustomActionBarActions';
import DownloadsModal from '../../GenericConsignment/DownloadsModal';
import BulkCreateRouteModal from '../../retail/trip/BulkCreateRouteModal';
import BulkTripUploadModal from '@components/pages/retail/trip/BulkTripUploadModal';
import BulkUploadConsignment from '../../retail/dashboard/BulkUploadConsignment';
import OutscanModal from '../../GenericConsignment/actions/OutscanModal';
import QcCheckModal from '../../retail/dashboard/QcCheckModal/QcCheckModal';
import InventoryInscanModal from '../../retail/dashboard/InventoryInscanModal/InventoryInscanModal';
import CustomTripCreation from '../../retail/dashboard/CustomTripCreationModal';
import SubMenu from 'antd/lib/menu/SubMenu';
import { actionsConfig } from './utils';
import ScanSelectedModal from './ScanSelectedModal';
import BulkUploadModal from './BulkUploadModal';
import { getViewBuckets } from '../../../../utils/genericConfig/utils';
import SimplePaginator from '../../../common/SimplePaginator';
import { SettingIcon } from '../../../common/icons/SettingsIcon';
import CustomColumnsModal from '../../../pages/dashboard/CustomColumnsModal';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { TABLE_ACTION, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
import GenericActionButton from 'src/components/common/GenericActionButton';
import { ActionButtonIcon } from 'src/components/common/icons/ActionButtonIcon';
import EllipsisIcon from 'src/components/common/icons/EllipsisIcon';
import ScanAndPrintLabelModal from '../ScanAndPrintLabelModal';
const Option = Select.Option;
const { Text } = Typography;
const styles = (theme: ThemeType): CssType => ({
  actionButton: {
    backgroundColor: `${theme.colors.primaryColor}08`,
    marginRight: '8px',
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.sizes.bodyText,
    color: theme.colors.primaryColor,
    border: `0.5px solid ${theme.colors.primaryColor}`,
    transition: 'background-color 0.3s, border 0.3s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: `${theme.colors.primaryColor}0F`,
      color: theme.colors.primaryColor,
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& span>svg': {
      fontSize: '23px',
    },
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.surfaceBg,
    '& .ant-btn-link': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.sizes.bodyText,
      color: theme.colors.primaryColor,
    },
  },
  moreActions: {
    '& .ant-dropdown-menu': {
      fontSize: theme.sizes.bodyText,
      maxHeight: '200px',
      overflow: 'auto',
    },
  },
  topBarRightDiv: {
    display: 'flex',
    fontSize: theme.sizes.bodyText,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .ant-select-selector': {
      backgroundColor: `${theme.colors.primaryColor}08 !important`,
    },
    '& .ant-btn-icon-only': {
      backgroundColor: `${theme.colors.primaryColor}08 !important`,
      '& .anticon svg': {
        color: theme.colors.primaryColor,
      }
    },
    '& .ant-select-selection-item': {
      color: theme.colors.primaryColor,
    },
    '& .ant-select-arrow': {
      color: theme.colors.primaryColor,
    },
  },
  extraActions: {
    display: 'flex',
    fontSize: theme.sizes.bodyText,
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 1 0',
  },
  actionButtonDiv: {
    display: 'flex',
    gap: '4px',
    marginRight: '8px',
  },
  ellipsisButtonMoreActions: {
    '& span>svg': {
      fontSize: '23px',
    }
  },
  ellipsisIconButton: {
    backgroundColor: `${theme.colors.primaryColor}08`,
    marginRight: '8px',
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.sizes.bodyText,
    color: theme.colors.primaryColor,
    border: `0.5px solid ${theme.colors.primaryColor}`,
    transition: 'background-color 0.3s, border 0.3s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: `${theme.colors.primaryColor}0F`,
      color: theme.colors.primaryColor,
      border: `0.5px solid ${theme.colors.primaryColor}`,
    },
    '& .anticon svg': {
      height: '21px',
      width: '23px',
    },
    width: '20px',
  },
  primaryColorIcon: {
    color: theme.colors.primaryColor,
  }
});

const CustomActionBar = (props: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(CustomActionsBarReducer, defaultState);
  const {
    createRouteForAll,
    createRouteFiltered,
    actionType,
    downloadHus,
    cnVerificationViaExcel,
    currentActionConfig,
    tripCreationType,
    bulkLabelType,
    actionStatus,
  } = state;
  const {
    createRouteModal,
    downloadsModal,
    bulkUploadModal,
    bulkCreateRouteModal,
    cnOutscanModal,
    qcCheckModal,
    inventoryInscanModal,
    customTripModal,
    genericScanSelectModal,
    moveCNModal,
    bulkDeleteCNModal,
    genericBulkUploadModal,
    bulkTripModal,
    customColumnModal,
    bulkUpdatePiecesModal,
    bulkUpdateModal,
    scanAndPrintLabelModal,
  } = state.modalState;
  const {
    classes,
    outsideActions,
    insideActions,
    asyncRequestCreation,
    selectedRowKeys,
    viewType,
    bucket,
    currHub,
    globalAppliedFilters,
    bucketAppliedFilters,
    date,
    timeSlots,
    tableData,
    handleModalClose,
    isTableLoading,
    currentPageNumber,
    perPageCount,
    isNextPresent,
    handleFirstPageClick,
    handlePrevClick,
    handleNextClick,
    handlePageCountChange,
    onReloadClick,
    hideActions,
    showDownloadButton,
    onDownloadClick,
    showTripStartTime,
    columnsToShow,
    fullColumnList,
    showFiltersAndActions,
    cnRescheduleDateValidation,
    showDownloadsModal,
    handleCustomColumnsModalClose,
    style,
    t,
  } = props;
  const BUCKETS = getViewBuckets(viewType);
  const getActionItem = (subAction) => {
    return (
      <Menu.Item onClick={() => onActionClick(subAction)}>
        {t(subAction.label)}
      </Menu.Item>
    );
  };
  const getActionMenu = (actionDetails) => {
    return actionDetails.children.map((ele) => getActionItem(ele));
  };

  const renderOutsideAction = (action) => {
    if(action?.id && action.label) {
      action.label = t(action.id);
    }
    const actionDetails: any =
      actionsConfig.filter((ele) => ele.id === action.id)[0] || {};
    return actionDetails.children instanceof Array &&
      actionDetails.children.length ? (
      <Dropdown
        placement="bottomLeft"
        trigger={['click']}
        overlay={<Menu>{getActionMenu(actionDetails)}</Menu>}
      >
        <Button
          className={classes.actionButton}
          onClick={() => onActionClick(actionDetails)}
        >
          {action.label || actionDetails.label}
        </Button>
      </Dropdown>
    ) : (
      <GenericActionButton
        className={classes.actionButton}
        onClick={() => onActionClick(actionDetails)}
      >
        {action.label || actionDetails.label}
      </GenericActionButton>
    );
  };

  const onActionClick = (actionDetails) => {
    if (actionDetails.requireRows && !selectedRowKeys?.length) {
      showOneOrMoreMessage();
      return;
    }
    trackGaEvents({actionType: TABLE_ACTION}, {actionDetails, bucket});
    dispatch(setActionConfig(actionDetails));
    if (actionDetails.onClick) {
      dispatch(actionDetails.onClick);
    }
    if (actionDetails.type === 'route') {
      history.push({
        pathname: actionDetails.route(),
        state:
          actionDetails.id === 'view_requests' ? { action: actionType } : {},
      });
    }
  };

  const showOneOrMoreMessage = () => {
    message.info(t('Please select one or more consignment'));
  };

  const onModalClose = (isSuccess: any = false, modalName = null) => {
    dispatch(closeAllModals());
    if (modalName === 'customColumnsModal' && handleCustomColumnsModalClose) {
      handleCustomColumnsModalClose(isSuccess);
    } else {
      handleModalClose(isSuccess);
    }
  };

  const renderInsideAction = (action) => {
    if(action?.id && action.label) {
      action.label = t(action.id);
    }
    const actionDetails: any =
      actionsConfig.filter((ele) => ele.id === action.id)[0] || {};
    return actionDetails.children instanceof Array &&
      actionDetails.children.length ? (
      <SubMenu
        title={action.label || actionDetails.label}
        onTitleClick={() => onActionClick(actionDetails)}
      >
        {getActionMenu(actionDetails)}
      </SubMenu>
    ) : (
      getActionItem(actionDetails)
    );
  };

  const onOkClick = () => {
    dispatch(currentActionConfig.onOkClick);
  };

  return (
    <div style={{ width: '100%', ...(style ?? {}) }}>
      <div className={classes.actionsBar}>
        {!hideActions && <div className={classes.actionButtonDiv}>
          <ActionButtonIcon/>
          <span>{t('Actions')}</span>
        </div>}
        {outsideActions.map((ele) => renderOutsideAction(ele))}
        <div className={classes.extraActions}>
          {insideActions?.length ? (
            <Dropdown
              placement="bottomLeft"
              trigger={['click']}
              overlay={
                <Menu>
                  {insideActions.map((ele) => renderInsideAction(ele))}
                </Menu>
              }
              overlayClassName={classes.moreActions}
            >
              <Button type="link" className={classes.ellipsisIconButton}> <EllipsisIcon/> </Button>
            </Dropdown>
          ) : (
            <div></div>
          )}
          <div className={classes.topBarRightDiv}>
            {selectedRowKeys?.length > 0 && (
              <Space>
                <Text style={{ marginRight: '5px' }}>
                  {t('Count')}: {selectedRowKeys.length}
                </Text>
              </Space>
            )}
            {BUCKETS[bucket].hidePagination ? null : (
              <React.Fragment>
                <SimplePaginator
                  loading={isTableLoading}
                  onFirstClick={handleFirstPageClick}
                  onPrevClick={handlePrevClick}
                  onNextClick={handleNextClick}
                  isNext={isNextPresent}
                  pageNumber={currentPageNumber}
                />
                {BUCKETS[bucket].hidePerPageCount ? null : (
                  <Select
                    onChange={handlePageCountChange}
                    style={{ marginLeft: '12px'}}
                    value={perPageCount ? String(perPageCount) : perPageCount}
                    dropdownClassName={classes.dropdownMenu}
                  >
                    <Option value="50" className={classes.dropdownMenuOption} >50/{t('page')}</Option>
                    <Option value="100" className={classes.dropdownMenuOption}>100/{t('page')}</Option>
                    <Option value="250" className={classes.dropdownMenuOption}>250/{t('page')}</Option>
                  </Select>
                )}
              </React.Fragment>
            )}
            <Button
              style={{ marginLeft: '8px' }}
              icon={<ReloadOutlined />}
              onClick={onReloadClick}
            ></Button>
            {showDownloadButton && (
              <Button
                style={{ marginLeft: '8px' }}
                icon={<DownloadOutlined />}
                onClick={onDownloadClick}
              ></Button>
            )}
            <a className={classes.primaryColorIcon} style={{ margin: '5px 0 0 15px' }}>
              <SettingIcon
                style={{ fontSize: '15px' }}
                onClick={() =>
                  dispatch(switchModalVisibility('customColumnModal', true))
                }
              />
            </a>
          </div>
        </div>
      </div>
      {createRouteModal && (
        <CreateRouteModal
          isAsync={asyncRequestCreation}
          consignments={selectedRowKeys}
          isVisible={createRouteModal}
          handleModalClose={(res: any) => onModalClose(res)}
          selectedHub={currHub}
          isAll={createRouteForAll}
          filters={globalAppliedFilters}
          isFiltered={createRouteFiltered}
          bucketFilters={bucketAppliedFilters}
          timeSlots={timeSlots}
          dateFilter={date}
          action={actionType}
          viewType={viewType}
          bucket={bucket}
          showTripStartTime={showTripStartTime}
        />
      )}
      {(downloadsModal || showDownloadsModal) && (
        <DownloadsModal
          isVisible={downloadsModal || showDownloadsModal}
          viewType={viewType}
          bucket={bucket}
          downloadHus={downloadHus}
          onModalClose={() => onModalClose(false)}
        />
      )}
      {bulkCreateRouteModal && (
        <BulkCreateRouteModal
          isAsync={asyncRequestCreation}
          consignments={tableData}
          isVisible={bulkCreateRouteModal}
          handleModalClose={(res: any) => onModalClose(res)}
          selectedHub={currHub}
          timeSlots={timeSlots}
          dateFilter={date}
          action={actionType}
          viewType={viewType}
          bucket={bucket}
          onCustomTripModalClose={(res) => onModalClose(res)}
          filters={globalAppliedFilters}
          showTripStartTime={showTripStartTime}
        />
      )}
      {bulkUploadModal && (
        <BulkUploadConsignment
          isVisible={bulkUploadModal}
          cnVerificationViaExcel={cnVerificationViaExcel}
          handleModalClose={(res) => onModalClose(res)}
        />
      )}
      {(bulkUpdateModal || bulkUpdatePiecesModal) && (
        <BulkUploadConsignment
          isVisible={bulkUpdateModal || bulkUpdatePiecesModal}
          handleModalClose={(res) => onModalClose(res)}
          updateConsignment={true}
          isPiecesUpdate={bulkUpdatePiecesModal}
        />
      )}
      {cnOutscanModal && (
        <OutscanModal
          isVisible={cnOutscanModal}
          consignmentList={selectedRowKeys}
          onModalClose={(res) => onModalClose(res)}
        />
      )}
      {qcCheckModal && (
        <QcCheckModal
          isVisible={qcCheckModal}
          onModalClose={(res) => onModalClose(res)}
          handleModalClose={() => onModalClose(false)}
        />
      )}
      {inventoryInscanModal && (
        <InventoryInscanModal
          isVisible={inventoryInscanModal}
          handleModalClose={() => onModalClose(false)}
        />
      )}
      {customTripModal && (
        <CustomTripCreation
          isAsync={asyncRequestCreation}
          consignments={selectedRowKeys}
          allConsignments={tableData}
          isVisible={customTripModal}
          tripCreationType={tripCreationType}
          handleModalClose={(res) => onModalClose(res)}
          timeSlots={timeSlots}
          hubId={currHub.id}
          filters={globalAppliedFilters}
          bucketFilters={bucketAppliedFilters}
          action={actionType}
          viewType={viewType}
          showTripStartTime={showTripStartTime}
          bucket={bucket}
          validationBeforeAddingCN={cnRescheduleDateValidation}
        />
      )}
      {genericScanSelectModal && (
        <ScanSelectedModal
          viewType={viewType}
          isVisible={genericScanSelectModal}
          consignmentList={selectedRowKeys}
          onModalClose={(res) => onModalClose(res)}
          currentActionConfig={currentActionConfig}
          bulkLabelType={bulkLabelType}
          actionStatus={actionStatus}
          onOkClick={onOkClick}
          isMoveCNModalVisible={moveCNModal}
          isBulkDeleteCNModalVisible={bulkDeleteCNModal}
        />
      )}
      {genericBulkUploadModal && (
        <BulkUploadModal
          isVisible={genericBulkUploadModal}
          onModalClose={(res) => onModalClose(res)}
          currentActionConfig={currentActionConfig}
          bulkLabelType={bulkLabelType}
          actionStatus={actionStatus}
          onOkClick={onOkClick}
        />
      )}
      {bulkTripModal && (
        <BulkTripUploadModal
          isVisible={bulkTripModal}
          currHub={currHub}
          onModalClose={(res) => onModalClose(res)}
          currentActionConfig={currentActionConfig}
        />
      )}
      {customColumnModal && (
        <CustomColumnsModal
          viewType={viewType}
          bucket={bucket}
          isVisible={customColumnModal}
          onModalClose={() => onModalClose(false, 'customColumnsModal')}
          showFiltersAndActions={showFiltersAndActions}
          defaultTab="filter"
          hideActions={hideActions}
          columnsToShow={columnsToShow}
          fullColumnList={fullColumnList}
        ></CustomColumnsModal>
      )}
      {scanAndPrintLabelModal && (
        <ScanAndPrintLabelModal
          isVisible={scanAndPrintLabelModal}
          onCancel={() => onModalClose()}
        />
      )}
    </div>
  );
};

const CustomActionBarStyled = withStyles(styles, { injectTheme: true })(
  CustomActionBar,
);

const mapStateToProps = (
  { masterData, genericConsignmentReducer, cachedData },
  ownProps,
) => {
  const { viewType, bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType];
  const currentBucketReducer = currentViewReducer.buckets[bucket];
  const allActions = Array.isArray(
    masterData.ops_full_actions_list_by_bucket?.[bucket],
  )
    ? masterData.ops_full_actions_list_by_bucket[bucket]
    : [];
  const actionsToShow = Array.isArray(
    masterData.ops_actions_list_by_bucket?.[bucket],
  )
    ? masterData.ops_actions_list_by_bucket[bucket]
    : [];
  return {
    outsideActions: actionsToShow
      .filter((ele) => ele.show_on_main_dashboard)
      .map((ele) => {
        return allActions.filter((item) => item.id === ele.id)[0];
      }),
    insideActions: actionsToShow
      .filter((ele) => !ele.show_on_main_dashboard)
      .map((ele) => {
        return allActions.filter((item) => item.id === ele.id)[0];
      }),
    asyncRequestCreation:
      masterData.auto_allocation_asynchronous_request || false,
    selectedRowKeys: currentBucketReducer.selectedRowKeys,
    currHub: cachedData.currHub,
    globalAppliedFilters: currentViewReducer.globalAppliedFilters,
    bucketAppliedFilters: currentBucketReducer.appliedFilters,
    date: {
      start:
        genericConsignmentReducer[ownProps.viewType].globalAppliedFilters
          .startDate,
      end: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters
        .endDate,
      dateFilterType:
        genericConsignmentReducer[ownProps.viewType].globalAppliedFilters
          .dateFilterType,
    },
    tableData: currentBucketReducer.data,
    isTableLoading: currentBucketReducer.isLoading,
    currentPageNumber: currentBucketReducer.pagination.currentPageNumber,
    perPageCount: currentBucketReducer.pagination.perPageCount,
    isNextPresent: currentBucketReducer.pagination.isNextPresent,
  };
};

export default withTranslation('translation')(connect(mapStateToProps)(CustomActionBarStyled));
