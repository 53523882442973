import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

const styles = (theme: ThemeType) => ({
  dropdownLoader: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    padding: '10px',
  },
});

class customSearchTagsInput extends React.PureComponent<any, any>{

  getInitialDropdownString = () => {
    const { t } = this.props;
    return this.props.searchText ? this.props.searchText : t('typeToStartSearching');
  };

  state: any = {
    isSearching: false,
    dropdownSearchString: this.getInitialDropdownString(),
  };

  renderOptions = () => {
    const { t } = this.props;
    return this.props.options.map(elem =>
      <Select.Option key={elem.key} value={elem.key}>{t(elem.label)}</Select.Option>,
    );
  };

  searchTimeOut: any = null;

  handleTextChange = (val) => {
    const { thresholdLength, t } = this.props;
    if (val.length >= (thresholdLength || 3)) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }
      this.setState({
        isSearching: true,
      });
      this.searchTimeOut = setTimeout(() => {
        this.searchForData(val);
      }, 300);
    } else if (this.searchTimeOut) {
      clearTimeout(this.searchTimeOut);
    }
  };

  searchForData = async (query) => {
    const { t } = this.props;
    if (!this.state.isSearching) {
      this.setState({
        isSearching: true,
      });
    }
    await this.props.searchFunction(query);
    this.setState({ isSearching: false });
    if (this.props.options.length === 0) {
      this.setState({ dropdownSearchString: t('no_result_found') });
    }
  };

  resetDropdownSearchString = () => {
    this.setState({ dropdownSearchString: this.getInitialDropdownString() });
  };

  render() {
    const { isSearching, dropdownSearchString } = this.state;
    const { classes, theme, options, filterNotRequired, ...otherProps } = this.props;

    return (
      <Select
        {...otherProps}
        autoClearSearchValue={false}
        showSearch
        labelInValue
        onFocus={this.resetDropdownSearchString}
        onSearch={this.handleTextChange}
        notFoundContent={isSearching ? <div className={classes.dropdownLoader}>
          <LoadingOutlined /></div> : dropdownSearchString }
        optionFilterProp="children"
        filterOption={(inputVal, option: any) => {
          if (!inputVal || filterNotRequired) {
            return true;
          }
          const optionval = option.children ? option.children.toLowerCase()  : '';
          return optionval.includes(inputVal.trim().toLowerCase());
        }}
      >
        {
          this.renderOptions()
        }
      </Select>
    );
  }
}

const SearchTagsInput = withStyles(styles, { injectTheme: true })(customSearchTagsInput);
export default withTranslation('translation')(SearchTagsInput);
