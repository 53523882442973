import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const en = require('./translations/en.json');
const hi = require('./translations/hi.json');
const ar = require('./translations/ar.json');
const ba = require('./translations/ba.json');
const fr = require('./translations/fr.json');

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  hi,
  ar,
  ba,
  fr,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['translation'],
    fallbackLng: 'en',
    resources,
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'lng',
    },
    keySeparator: false,
  });

export default i18n;
