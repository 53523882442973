export const orderTableColumns = {
  in_store_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer Address',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  ongoing_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer Address',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  completed_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer Address',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  unassigned_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer Address',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'reason',
      pretty_name: 'Unassigned Reason',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  cancelled_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
  ],
  undelivered_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer Address',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
};

export const orderTableColumnsPickily = {
  in_store_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'eta',
      pretty_name: 'ETA',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  ongoing_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'eta',
      pretty_name: 'ETA',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  completed_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  unassigned_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'reason',
      pretty_name: 'Unassigned Reason',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  cancelled_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
  undelivered_orders: [
    {
      column_id: 'order_number',
      pretty_name: 'Order #',
    },
    {
      column_id: 'order_time',
      pretty_name: 'Order Time',
    },
    {
      column_id: 'customer_address',
      pretty_name: 'Customer',
    },
    {
      column_id: 'order_slot',
      pretty_name: 'Order Slot',
    },
    {
      column_id: 'order_count',
      pretty_name: 'No. of orders',
    },
    {
      column_id: 'order_status',
      pretty_name: 'Order Status',
    },
    {
      column_id: 'rider_count',
      pretty_name: 'Rider',
    },
    {
      column_id: 'courier_partner',
      pretty_name: 'Carrier Name',
    },
    {
      column_id: 'assigned_rider',
      pretty_name: 'Assigned Rider',
    },
    {
      column_id: 'planned_distance_hub_to_customer',
      pretty_name: 'Planned Distance (KM)',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Store ID',
    },
    {
      column_id: 'pickup_otp',
      pretty_name: 'Validation Code',
    },
  ],
};

export const riderTableColumns = [
  {
    column_id: 'worker_name',
    pretty_name: 'Rider Name',
  },
  {
    column_id: 'on_road_time',
    pretty_name: 'On Road Time',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'etr',
    pretty_name: 'ETR',
  },
  {
    column_id: 'curr_average_score',
    pretty_name: "Today's Score",
  },
];

export const riderTableColumnsPickily = [
  {
    column_id: 'worker_name',
    pretty_name: 'Rider',
  },
  {
    column_id: 'etr',
    pretty_name: 'ETR',
  },
  {
    column_id: 'assigned_orders',
    pretty_name: 'Orders Assigned',
  },
  {
    column_id: 'on_road_time',
    pretty_name: 'On Road Time',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'curr_average_score',
    pretty_name: "Today's Score",
  },
  {
    column_id: 'vehicle_type',
    pretty_name: 'Vehicle Type',
  },
  {
    column_id: 'order_count',
    pretty_name: 'Order Count',
  },
];

export const orderHistoryColumns = [
  {
    column_id: 'order_number',
    pretty_name: 'Order No.',
  },
  {
    column_id: 'order_date_time',
    pretty_name: 'Date & Time',
  },
  {
    column_id: 'rider_name',
    pretty_name: 'Rider Name',
  },
  {
    column_id: 'rider_number',
    pretty_name: 'Rider Contact',
  },
  {
    column_id: 'delivery_time',
    pretty_name: 'Delivery Time',
  },
  {
    column_id: 'load_time',
    pretty_name: 'Load Time',
  },
  {
    column_id: 'rack_time',
    pretty_name: 'Rack Time',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
];
export const riderManagementColumns = [
  {
    column_id: 'worker_name',
    pretty_name: 'Rider Name',
  },
  {
    column_id: 'worker_type',
    pretty_name: 'Rider Type',
  },
  {
    column_id: 'worker_code',
    pretty_name: 'Employee ID',
  },
  {
    column_id: 'worker_number',
    pretty_name: 'Rider Contact',
  },
  {
    column_id: 'vehicle_category_label',
    pretty_name: 'Vehicle Type',
  },
  {
    column_id: 'vehicle_registration_number',
    pretty_name: 'Vehicle Registration Number',
  },
  {
    column_id: 'address',
    pretty_name: 'Address',
  },
  {
    column_id: 'expiry_date',
    pretty_name: 'DL Expiry Date',
  },
  {
    column_id: 'driving_license',
    pretty_name: 'View DL',
  },
  {
    column_id: 'comments',
    pretty_name: 'Comments',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'last_location_captured_at',
    pretty_name: 'Last Seen',
  },
  {
    column_id: 'preferred_vehicle_type',
    pretty_name: 'Preferred Vehicle Type (I)',
  },
  {
    column_id: 'second_preferred_vehicle_type',
    pretty_name: 'Preferred Vehicle Type (II)',
  },
  {
    column_id: 'app_version',
    pretty_name: 'RiderApp Version',
  },
  {
    column_id: 'cod_limit',
    pretty_name: 'COD Limit',
  },
  {
    column_id: 'hub_name',
    pretty_name: 'Hub Name',
  },
  {
    column_id: 'worker_ops_status',
    pretty_name: 'Rider ops status',
  },
  {
    column_id: 'shifts',
    pretty_name: 'Shifts',
  },
  {
    column_id: 'otp_status',
    pretty_name: 'OTP Verified',
  },
  {
    column_id: 'otp',
    pretty_name: 'OTP',
  },

];
export const riderOrderHistoryColumns = [
  {
    column_id: 'order_number',
    pretty_name: 'Order No.',
  },
  {
    column_id: 'address',
    pretty_name: 'Customer Address',
  },
  {
    column_id: 'bill_amount',
    pretty_name: 'Bill Amount & Payment Mode',
  },
  {
    column_id: 'start_bike_time',
    pretty_name: 'Start Bike Time',
  },
  {
    column_id: 'reached_gate_time',
    pretty_name: 'Reached Gate Time',
  },
  {
    column_id: 'status',
    pretty_name: 'Status',
  },
  {
    column_id: 'reached_store_time',
    pretty_name: 'Reached Store Time',
  },
];

export const ppoRosterColumns = [
  {
    column_id: 'start_time',
    pretty_name: 'Start Shift Time',
  },
  {
    column_id: 'end_time',
    pretty_name: 'End Shift Time',
  },
  {
    column_id: 'worker_type',
    pretty_name: 'Rider Type',
  },
  {
    column_id: 'input_worker_details',
    pretty_name: 'Rider Codes',
  },
  {
    column_id: 'broadcast_message',
    pretty_name: 'Broadcast Message',
  },
  {
    column_id: 'total_slots',
    pretty_name: 'Required Riders',
  },
  {
    column_id: 'accepted_slots',
    pretty_name: 'Accepted Riders',
  },
];

export const plannedRosterColumns = [
  {
    column_id: 'shift_name',
    pretty_name: 'Shift Name',
  },
  {
    column_id: 'riders',
    pretty_name: 'Riders',
  },
  {
    column_id: 'slot_start_date',
    pretty_name: 'Shift Start Date',
  },
  {
    column_id: 'slot_end_date',
    pretty_name: 'Shift End Date',
  },
  {
    column_id: 'planned_shift_start_time',
    pretty_name: 'Start Shift Time',
  },
  {
    column_id: 'planned_shift_end_time',
    pretty_name: 'End Shift Time',
  },
  // {
  //   column_id: 'actions',
  //   pretty_name: 'Actions',
  // },
];

export const breakDetailsColumns = [
  {
    column_id: 'startTime',
    pretty_name: 'Start Time',
  },
  {
    column_id: 'endTime',
    pretty_name: 'End Time',
  },
  {
    column_id: 'duration',
    pretty_name: 'Duration',
  },
];

export const riderOrderDetails = [
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 190,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Orders Considered',
    width: 100,
  },
  {
    dataIndex: 'single_run_count',
    key: 'single_run_count',
    title: 'Single Run Orders',
    width: 130,
  },
  {
    dataIndex: 'dot_lt_30',
    key: 'dot_lt_30',
    title: 'DOT% <30 min',
    width: 130,
  },
  {
    dataIndex: 'avg_delivery_time',
    key: 'avg_delivery_time',
    title: 'ADT in MINS',
    width: 130,
  },
  {
    dataIndex: 'avg_load_time',
    key: 'avg_load_time',
    title: 'Load Time',
    width: 130,
  },
  {
    dataIndex: 'avg_rack_time',
    key: 'avg_rack_time',
    title: 'Rack Time',
    width: 130,
  },
  {
    dataIndex: 'assignment_startbike_time',
    key: 'assignment_startbike_time',
    title: 'Start bike',
    width: 130,
  },
  {
    dataIndex: 'avg_drive_time',
    key: 'avg_drive_time',
    title: 'Drive Time',
    width: 130,
  },
  {
    dataIndex: 'reachedgate_delivered_time',
    key: 'reachedgate_delivered_time',
    title: 'Reached Gate to Delivered (In Min)',
    width: 130,
  },
  {
    dataIndex: 'delivered_reachedstore_time',
    key: 'delivered_reachedstore_time',
    title: 'Delivered to Reached Store (In Min)',
    width: 130,
  },
  {
    dataIndex: 'dot_lt_20',
    key: 'dot_lt_20',
    title: 'DOT% <20 min',
    width: 130,
  },
  {
    dataIndex: 'dot_20to23',
    key: 'dot_20to23',
    title: 'DOT% 20-23 min',
    width: 130,
  },
  {
    dataIndex: 'dot_23to25',
    key: 'dot_23to25',
    title: 'DOT% 23-25 min',
    width: 130,
  },
  {
    dataIndex: 'dot25to27',
    key: 'dot25to27',
    title: 'DOT% 25-27 min',
    width: 130,
  },
  {
    dataIndex: 'dot27to29',
    key: 'dot27to29',
    title: 'DOT% 27-29 min',
    width: 130,
  },
  {
    dataIndex: 'dot_29to30',
    key: 'dot_29to30',
    title: 'DOT% 29-30 min',
    width: 130,
  },
  {
    dataIndex: 'dot_30to35',
    key: 'dot_30to35',
    title: 'DOT% 30-35 min',
    width: 130,
  },
  {
    dataIndex: 'dot_35to40',
    key: 'dot_35to40',
    title: 'DOT% 35-40 min',
    width: 130,
  },
  {
    dataIndex: 'dot_40to45',
    key: 'dot_40to45',
    title: 'DOT% 40-45 min',
    width: 130,
  },
  {
    dataIndex: 'dot_gt_45',
    key: 'dot_gt_45',
    title: 'DOT% 45 min+',
    width: 130,
  },
];
export const riderExceptions = [
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 190,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'dt_lt_2',
    key: 'dt_lt_2',
    title: '# Orders with Drive time <2 min',
    width: 130,
  },
  {
    dataIndex: 'dt_2to5',
    key: 'dt_2to5',
    title: '# Orders with Drive time 2-5 min',
    width: 130,
  },
  {
    dataIndex: 'dt_gt_30',
    key: 'dt_gt_30',
    title: '# Orders with Drive time >30 min',
    width: 130,
  },
];
export const riderScore = [
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 190,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'order_count',
    key: 'order_count',
    title: 'Total Orders',
    width: 130,
  },
  {
    dataIndex: 'score_lt_10',
    key: 'score_lt_10',
    title: 'Orders with score <10',
    width: 130,
  },
  {
    dataIndex: 'score_10to14',
    key: 'score_10to14',
    title: 'Orders with score >=10 and <=14',
    width: 130,
  },
  {
    dataIndex: 'score_gt_14',
    key: 'score_gt_14',
    title: 'Orders with score >14',
    width: 130,
  },
];
export const riderScoreDetails = [
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'order_number',
    key: 'order_number',
    title: 'Order No.',
    width: 100,
  }, {
    dataIndex: 'order_date',
    key: 'order_date',
    title: 'Order Date',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 190,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'sb_geo_marking',
    key: 'sb_geo_marking',
    title: 'Start Bike Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'd_geo_marking',
    key: 'd_geo_marking',
    title: 'Delivery Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'delivered_latlng',
    key: 'delivered_latlng',
    title: 'Delivered Latlong',
    width: 130,
  },
  {
    dataIndex: 'customer_latlng',
    key: 'customer_latlng',
    title: 'Customer Latlong',
    width: 130,
  },
  {
    dataIndex: 'rs_geo_marking',
    key: 'rs_geo_marking',
    title: 'Reached store Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'dt_marking',
    key: 'dt_marking',
    title: 'Delivery Timing Marking',
    width: 130,
  },
  {
    dataIndex: 'rst_marking',
    key: 'rst_marking',
    title: 'Reached store Timing Marking',
    width: 130,
  },
  {
    dataIndex: 'rider_score',
    key: 'rider_score',
    title: 'Net Score',
    width: 130,
  },
];
export const riderDPH = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex : 'check_in_hours',
    key : 'check_in_hours',
    title : 'Check In Hours',
    width: 130,
  },
  {
    dataIndex : 'break_hours',
    key : 'break_hours',
    title : 'Break Hours',
    width: 130,
  },
  {
    dataIndex : 'aux_hours',
    key : 'aux_hours',
    title : 'Aux Hours',
    width: 130,
  },
  {
    dataIndex : 'calculated_delivery_hours',
    key : 'calculated_delivery_hours',
    title : 'Calculated Delivery Hours',
    width: 130,
  },
  {
    dataIndex: 'order_count',
    key: 'order_count',
    title: 'Total Orders ',
    width: 130,
  },
  {
    dataIndex : 'orders_per_hour',
    key : 'orders_per_hour',
    title : 'Orders Per Hour',
    width: 130,
  },
];

export const storeDph = [
  {
    dataIndex : 'region',
    key : 'region',
    title : 'Region',
    width: 130,
  },
  {
    dataIndex : 'circle',
    key : 'circle',
    title : 'Circle',
    width: 130,
  },
  {
    dataIndex : 'city',
    key : 'city',
    title : 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex : 'check_in_hours',
    key : 'check_in_hours',
    title : 'Check In Hours',
    width: 130,
  },
  {
    dataIndex : 'break_hours',
    key : 'break_hours',
    title : 'Break Hours',
    width: 130,
  },
  {
    dataIndex : 'aux_hours',
    key : 'aux_hours',
    title : 'Aux Hours',
    width: 130,
  },
  {
    dataIndex : 'calculated_delivery_hours',
    key : 'calculated_delivery_hours',
    title : 'Calculated Delivery Hours',
    width: 130,
  },
  {
    dataIndex: 'order_count',
    key: 'order_count',
    title: 'Total Orders',
    width: 130,
  },
  {
    dataIndex : 'orders_per_hour',
    key : 'orders_per_hour',
    title : 'Orders Per Hour',
    width: 130,
  },
];
export const allocationDetails = [
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 190,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  { dataIndex: 'multi_run_count', key: 'multi_run_count', title: 'Multi Run Orders', width: 130 },
  { dataIndex: 'multi_run_per', key: 'multi_run_per', title: 'Multi Run %age', width: 130 },
  { dataIndex: 'single_run_count', key: 'single_run_count', title: 'Single Run Orders', width: 130 },
  { dataIndex: 'single_run_per', key: 'single_run_per', title: 'Single Run %age', width: 130 },
  {
    dataIndex: 'auto_allocation_per', key: 'auto_allocation_per',
    title: 'Auto Allocation %age', width: 130,
  },
  {
    dataIndex: 'manual_allocation_per', key: 'manual_allocation_per',
    title: 'Manual Allocation %age', width: 130,
  },
  {
    dataIndex: 'avg_assignment_time', key: 'avg_assignment_time',
    title: 'Assignment Time', width: 130,
  },
];
export const reassignDetails = [
  { dataIndex: 'order_number', key: 'order_number', title: 'Order No.', width: 100 },
  { dataIndex: 'order_date', key: 'order_date', title: 'Order Date', width: 130 },
  { dataIndex: 'order_time', key: 'order_time', title: 'Order Time', width: 130 },
  { dataIndex: 'hub_name', key: 'hub_name', title: 'Store Name', width: 130 },
  { dataIndex: 'hub_code', key: 'hub_code', title: 'Store Id', width: 130 },
  { dataIndex: 'worker_name', key: 'worker_name', title: 'Assigned Rider', width: 130 },
  { dataIndex: 'worker_code', key: 'worker_code', title: 'Assigned Rider Id', width: 130 },
  {
    dataIndex: 'reassigned_worker_name', key: 'reassigned_worker_name',
    title: 'Reassigned Rider', width: 130,
  },
  {
    dataIndex: 'reassigned_worker_code', key: 'reassigned_worker_code',
    title: 'Reassigned Rider Id', width: 130,
  },
  {
    dataIndex: 'reassigned_reason', key: 'reassigned_reason',
    title: 'Reason for Reassignment', width: 130,
  },
];
export const unresolvedOrders = [
  { dataIndex: 'order_number', key: 'order_number', title: 'Order No.', width: 100 },
  { dataIndex: 'order_date', key: 'order_date', title: 'Order Date', width: 130 },
  { dataIndex: 'order_time', key: 'order_time', title: 'Order Time', width: 130 },
  { dataIndex: 'makeline_time', key: 'makeline_time', title: 'Makeline Time', width: 130 },
  { dataIndex: 'hub_name', key: 'hub_name', title: 'Store Name', width: 130 },
  { dataIndex: 'hub_code', key: 'hub_code', title: 'Store Id', width: 130 },
];
export const storeSummary = [
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store ID',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Orders Considered',
    width: 100,
  },
  {
    dataIndex: 'dot_lt_30',
    key: 'dot_lt_30',
    title: 'DOT%< 30',
    width: 130,
  },
  {
    dataIndex: 'avg_delivery_time',
    key: 'avg_delivery_time',
    title: 'ADT in MINS',
    width: 130,
  },
  {
    dataIndex: 'avg_load_time',
    key: 'avg_load_time',
    title: 'LOAD TIME',
    width: 130,
  },
  {
    dataIndex: 'avg_rack_time',
    key: 'avg_rack_time',
    title: 'RACK TIME',
    width: 130,
  },
  {
    dataIndex: 'assignment_startbike_time',
    key: 'assignment_startbike_time',
    title: 'Start bike',
    width: 130,
  },
  {
    dataIndex: 'avg_drive_time',
    key: 'avg_drive_time',
    title: 'Drive Time',
    width: 130,
  },
  {
    dataIndex: 'reachedgate_delivered_time',
    key: 'reachedgate_delivered_time',
    title: 'Reached Gate to Delivered (In Min)',
    width: 130,
  },
  {
    dataIndex: 'delivered_reachedstore_time',
    key: 'delivered_reachedstore_time',
    title: 'Delivered to Reached Store (In Min)',
    width: 130,
  },
  {
    dataIndex: 'dot_lt_20',
    key: 'dot_lt_20',
    title: 'DOT%< 20',
    width: 130,
  },
  {
    dataIndex: 'avg_delivery_time_delivered',
    key: 'avg_delivery_time_delivered',
    title: 'ADT Delivered in Mins',
    width: 130,
  },
  {
    dataIndex: 'delivered_lt_20',
    key: 'delivered_lt_20',
    title: '20 min delivered %',
    width: 130,
  },
  {
    dataIndex: 'delivered_lt_30',
    key: 'delivered_lt_30',
    title: '30 min delivered %',
    width: 130,
  },
  {
    dataIndex: 'dot_20to23',
    key: 'dot_20to23',
    title: 'DOT% 20-23 min',
    width: 130,
  },
  {
    dataIndex: 'dot_23to25',
    key: 'dot_23to25',
    title: 'DOT% 23-25 min',
    width: 130,
  },
  {
    dataIndex: 'dot25to27',
    key: 'dot25to27',
    title: 'DOT% 25-27 min',
    width: 130,
  },
  {
    dataIndex: 'dot27to29',
    key: 'dot27to29',
    title: 'DOT% 27-29 min',
    width: 130,
  },
  {
    dataIndex: 'dot_29to30',
    key: 'dot_29to30',
    title: 'DOT% 29-30 min',
    width: 130,
  },
  {
    dataIndex: 'dot_30to35',
    key: 'dot_30to35',
    title: 'DOT% 30-35 min',
    width: 130,
  },
  {
    dataIndex: 'dot_35to40',
    key: 'dot_35to40',
    title: 'DOT% 35-40 min',
    width: 130,
  },
  {
    dataIndex: 'dot_40to45',
    key: 'dot_40to45',
    title: 'DOT% 40-45 min',
    width: 130,
  },
  {
    dataIndex: 'dot_gt_45',
    key: 'dot_gt_45',
    title: 'DOT% 45 min+',
    width: 130,
  },
  {
    dataIndex: 'single_run_per',
    key: 'single_run_per',
    title: 'Single Run%',
    width: 130,
  },
  {
    dataIndex: 'manual_allocation_per',
    key: 'manual_allocation_per',
    title: 'Manual Allocation%',
    width: 130,
  },
  {
    dataIndex: 'auto_allocation_per',
    key: 'auto_allocation_per',
    title: 'Auto Allocation%',
    width: 130,
  },
  {
    dataIndex: 'delivered_outside_geofence_count',
    key: 'delivered_outside_geofence_count',
    title: '# Orders Delivered Outside Geofence',
    width: 130,
  },
  {
    dataIndex: 'delivered_outside_geofence_per',
    key: 'delivered_outside_geofence_per',
    title: 'Orders Delivered Outside Geofence %',
    width: 130,
  },
  {
    dataIndex: 'avg_distance_delivered_outside_customer_geofence',
    key: 'avg_distance_delivered_outside_customer_geofence',
    title: 'Avg Distance Delivered Outside Customer Geofence (in m)',
    width: 130,
  },
  {
    dataIndex: 'avg_distance_delivered_within_customer_geofence',
    key: 'avg_distance_delivered_within_customer_geofence',
    title: 'Avg Distance Delivered Within Customer Geofence (in m)',
    width: 130,
  },
  {
    dataIndex: 'avg_score',
    key: 'avg_score',
    title: 'Avg. Score',
    width: 130,
  },
  {
    dataIndex: 'dt_lt_2_per',
    key: 'dt_lt_2_per',
    title: 'Drive Time <2 min %',
    width: 130,
  },
  {
    dataIndex: 'left_at_store_per',
    key: 'left_at_store_per',
    title: 'Left at Store %',
    width: 130,
  },
];

export const order_delivered_reachedgate_metrics =
  [
    { dataIndex: 'order_number', key: 'order_number', title: 'Order No', width: 130 },
    { dataIndex: 'order_date', key: 'order_date', title: 'Date', width: 130 },
    { dataIndex: 'hub_code', key: 'hub_code', title: 'DP ID', width: 130 },
    { dataIndex: 'hub_name', key: 'hub_name', title: 'Name', width: 130 },
    {
      dataIndex: 'order_delivered_outside_geofence',
      key: 'order_delivered_outside_geofence',
      title: 'Order Delivered Outside Geofence', width: 130,
    },
    {
      dataIndex: 'customer_latlng', key: 'customer_latlng',
      title: 'Actual Delivered Lat-Long', width: 130,
    },
    {
      dataIndex: 'delivered_latlng', key: 'delivered_latlng',
      title: 'Punched Delivered Lat-Long', width: 130,
    },
    { dataIndex: 'distance_delivered_outside_customer_geofence', key: 'distance_delivered_outside_customer_geofence', title: 'Distance Delivered Outside Customer Geofence (in m)', width: 130 },
    { dataIndex: 'reachedgate_latlng', key: 'reachedgate_latlng', title: 'Punched Reached Gate Lat-Long', width: 130 },
    { dataIndex: 'left_at_store', key: 'left_at_store', title: 'Left at Store', width: 130 },
    { dataIndex: 'reachedgate_to_delivered', key: 'reachedgate_to_delivered', title: 'Reached Gate to Delivered Distance (in m)', width: 130 },
  ];

export const store_delivered_reachedgate_metrics =
  [
    // { dataIndex : 'id', key : 'id', title : 'Order No', width : 130 },
    { dataIndex: 'order_date', key: 'order_date', title: 'Date', width: 130 },
    { dataIndex: 'hub_code', key: 'hub_code', title: 'DP ID', width: 130 },
    { dataIndex: 'hub_name', key: 'hub_name', title: 'Name', width: 130 },
    {
      dataIndex: 'delivered_count', key: 'delivered_count',
      title: '# Consider Orders', width: 130,
    },
    { dataIndex: 'delivered_outside_geofence_per', key: 'delivered_outside_geofence_per', title: 'Orders Delivered Outside Geofence %age', width: 130 },
    { dataIndex: 'avg_distance_delivered_outside_customer_geofence', key: 'avg_distance_delivered_outside_customer_geofence', title: 'Avg Distance Delivered Outside Customer Geofence (in m)', width: 130 },
    {
      dataIndex: 'left_at_store_per', key: 'left_at_store_per',
      title: 'Left at Store %age', width: 130,
    },
    { dataIndex: 'avg_reachedgate_to_delivered', key: 'avg_reachedgate_to_delivered', title: 'Avg Reached Gate to Delivered Distance (in m)', width: 130 },
  ];

export const locality_summary = [
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store ID',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Orders Considered',
    width: 130,
  },
  {
    dataIndex: 'locality',
    key: 'locality',
    title: 'Locality',
    width: 200,
  },
  {
    dataIndex: 'dot_lt_30',
    key: 'dot_lt_30',
    title: 'DOT% <30 min',
    width: 130,
  },
  {
    dataIndex: 'avg_delivery_time',
    key: 'avg_delivery_time',
    title: 'ADT in MINS',
    width: 130,
  },
  {
    dataIndex: 'avg_load_time',
    key: 'avg_load_time',
    title: 'Load Time',
    width: 130,
  },
  {
    dataIndex: 'avg_rack_time',
    key: 'avg_rack_time',
    title: 'Rack Time',
    width: 130,
  },
  {
    dataIndex: 'assignment_startbike_time',
    key: 'assignment_startbike_time',
    title: 'Start bike',
    width: 130,
  },
  {
    dataIndex: 'avg_drive_time',
    key: 'avg_drive_time',
    title: 'Drive Time',
    width: 130,
  },
  {
    dataIndex: 'reachedgate_delivered_time',
    key: 'reachedgate_delivered_time',
    title: 'Reached Gate to Delivered (In Min)',
    width: 130,
  },
  {
    dataIndex: 'delivered_reachedstore_time',
    key: 'delivered_reachedstore_time',
    title: 'Delivered to Reached Store (In Min)',
    width: 130,
  },
  {
    dataIndex: 'dot_lt_20',
    key: 'dot_lt_20',
    title: 'DOT%< 20',
    width: 130,
  },
  {
    dataIndex: 'dot_20to23',
    key: 'dot_20to23',
    title: 'DOT% 20-23 min',
    width: 130,
  },
  {
    dataIndex: 'dot_23to25',
    key: 'dot_23to25',
    title: 'DOT% 23-25 min',
    width: 130,
  },
  {
    dataIndex: 'dot25to27',
    key: 'dot25to27',
    title: 'DOT% 25-27 min',
    width: 130,
  },
  {
    dataIndex: 'dot27to29',
    key: 'dot27to29',
    title: 'DOT% 27-29 min',
    width: 130,
  },
  {
    dataIndex: 'dot_29to30',
    key: 'dot_29to30',
    title: 'DOT% 29-30 min',
    width: 130,
  },
  {
    dataIndex: 'dot_30to35',
    key: 'dot_30to35',
    title: 'DOT% 30-35 min',
    width: 130,
  },
  {
    dataIndex: 'dot_35to40',
    key: 'dot_35to40',
    title: 'DOT% 35-40 min',
    width: 130,
  },
  {
    dataIndex: 'dot_40to45',
    key: 'dot_40to45',
    title: 'DOT% 40-45 min',
    width: 130,
  },
  {
    dataIndex: 'dot_gt_45',
    key: 'dot_gt_45',
    title: 'DOT% 45 min+',
    width: 130,
  },
];

export const rider_score_summary = [
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Orders Considered',
    width: 130,
  },
  {
    dataIndex: 'avg_score',
    key: 'avg_score',
    title: 'Avg Score',
    width: 130,
  },
  {
    dataIndex: 'avg_sb_geo_marking',
    key: 'avg_sb_geo_marking',
    title: 'Start Bike Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'avg_sb_rg_marking',
    key: 'avg_sb_rg_marking',
    title: 'Start Bike To Reached Gate',
    width: 130,
  },
  {
    dataIndex: 'avg_d_store_geo_marking',
    key: 'avg_d_store_geo_marking',
    title: 'Delivered Store Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'avg_d_geo_marking',
    key: 'avg_d_geo_marking',
    title: 'Delivered Customer Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'avg_dt_marking',
    key: 'avg_dt_marking',
    title: 'Delivered vs Google Eta',
    width: 130,
  },
  {
    dataIndex: 'avg_rg_d_marking',
    key: 'avg_rg_d_marking',
    title: 'Reached Gate To Delivered',
    width: 130,
  },
  {
    dataIndex: 'avg_dt_marking_multi_order',
    key: 'avg_dt_marking_multi_order',
    title: 'Delivered Multi Order',
    width: 130,
  },
  {
    dataIndex: 'avg_rs_geo_marking',
    key: 'avg_rs_geo_marking',
    title: 'Reached Store Geofence Marking',
    width: 130,
  },
  {
    dataIndex: 'avg_rst_marking',
    key: 'avg_rst_marking',
    title: 'Reached Store vs Google Eta',
    width: 130,
  },
  {
    dataIndex: 'avg_d_rst_marking',
    key: 'avg_d_rst_marking',
    title: 'Delivered to Reached Store',
    width: 130,
  },
  {
    dataIndex: 'delivered_outside_geofence_count',
    key: 'delivered_outside_geofence_count',
    title: '# Orders Delivered Outside Geofence',
    width: 130,
  },
  {
    dataIndex: 'delivered_outside_geofence_per',
    key: 'delivered_outside_geofence_per',
    title: 'Orders Delivered Outside Geofence %age',
    width: 130,
  },
  {
    dataIndex: 'avg_distance_delivered_outside_customer_geofence',
    key: 'avg_distance_delivered_outside_customer_geofence',
    title: 'Avg Distance Delivered Outside Customer Geofence (in m)',
    width: 130,
  },
  {
    dataIndex: 'avg_distance_delivered_within_customer_geofence',
    key: 'avg_distance_delivered_within_customer_geofence',
    title: 'Avg Distance Delivered Within Customer Geofence (in m)',
    width: 130,
  },
];

export const rider_gps_km = [
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store ID',
    width: 130,
  },
  {
    dataIndex: 'gps_km',
    key: 'gps_km',
    title: 'GPS KM',
    width: 130,
  },
];

export const rider_distance_metrics = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store ID',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_order_count_no_exclude_filter',
    key: 'worker_order_count_no_exclude_filter',
    title: 'Total Orders',
    width: 130,
  },
  {
    dataIndex: 'worker_excluded_count',
    key: 'worker_excluded_count',
    title: 'Excluded Orders',
    width: 130,
  },
  {
    dataIndex: 'order_count',
    key: 'order_count',
    title: 'Orders Considered',
    width: 130,
  },
  {
    dataIndex: 'hub_delivery_distance_travelled_km',
    key: 'hub_delivery_distance_travelled_km',
    title: 'Forward Distance Travelled (KMs)',
    width: 130,
  },
  {
    dataIndex: 'hub_return_distance_travelled_km',
    key: 'hub_return_distance_travelled_km',
    title: 'Reverse Distance Travelled (KMs)',
    width: 130,
  },
  {
    dataIndex: 'hub_total_distance_travelled_km',
    key: 'hub_total_distance_travelled_km',
    title: 'Total Distance (KMs)',
    width: 130,
  },
  {
    dataIndex: 'hub_distance_per_order',
    key: 'hub_distance_per_order',
    title: 'Distance Per Order',
    width: 130,
  },
];

export const rider_trip_order_count = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'ocount_1',
    key: 'ocount_1',
    title: 'Order Count 1',
    width: 130,
  },
  {
    dataIndex: 'ocount_2',
    key: 'ocount_2',
    title: 'Order Count 2',
    width: 130,
  },
  {
    dataIndex: 'ocount_3',
    key: 'ocount_3',
    title: 'Order Count 3',
    width: 130,
  },
  {
    dataIndex: 'ocount_4',
    key: 'ocount_4',
    title: 'Order Count 4',
    width: 130,
  },
  {
    dataIndex: 'ocount_5',
    key: 'ocount_5',
    title: 'Order Count 5',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Total Delivered',
    width: 130,
  },
];

export const store_gps_km = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store ID',
    width: 130,
  },
  {
    dataIndex: 'gps_km',
    key: 'gps_km',
    title: 'GPS KM',
    width: 130,
  },
];

export const store_trip_order_count = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'ocount_1',
    key: 'ocount_1',
    title: 'Order Count 1',
    width: 130,
  },
  {
    dataIndex: 'ocount_2',
    key: 'ocount_2',
    title: 'Order Count 2',
    width: 130,
  },
  {
    dataIndex: 'ocount_3',
    key: 'ocount_3',
    title: 'Order Count 3',
    width: 130,
  },
  {
    dataIndex: 'ocount_4',
    key: 'ocount_4',
    title: 'Order Count 4',
    width: 130,
  },
  {
    dataIndex: 'ocount_5',
    key: 'ocount_5',
    title: 'Order Count 5',
    width: 130,
  },
  {
    dataIndex: 'delivered_count',
    key: 'delivered_count',
    title: 'Total Delivered',
    width: 130,
  },
];

export const order_summary = [
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 130,
  },
  {
    dataIndex: 'order_date',
    key: 'order_date',
    title: 'Order Date',
    width: 130,
  },
  {
    dataIndex: 'order_number',
    key: 'order_number',
    title: 'Order Number',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'order_delivery_time',
    key: 'order_delivery_time',
    title: 'ADT In Mins',
    width: 130,
  },
  {
    dataIndex: 'order_load_time',
    key: 'order_load_time',
    title: 'Load Time',
    width: 130,
  },
  {
    dataIndex: 'order_rack_time',
    key: 'order_rack_time',
    title: 'Rack Time',
    width: 130,
  },
  {
    dataIndex: 'order_assignment_startbike_time',
    key: 'order_assignment_startbike_time',
    title: 'Start Bike',
    width: 130,
  },
  {
    dataIndex: 'order_delivery_time',
    key: 'order_delivery_time',
    title: 'Drive Time',
    width: 130,
  },
  {
    dataIndex: 'order_reachedgate_delivered_time',
    key: 'order_reachedgate_delivered_time',
    title: 'Reached Gate to Delivered (In Min)',
    width: 130,
  },
  {
    dataIndex: 'order_delivered_reachedstore_time',
    key: 'order_delivered_reachedstore_time',
    title: 'Delivered to Reached Store (In Min)',
    width: 130,
  },
  {
    dataIndex: 'order_delivered_within_geofence',
    key: 'order_delivered_within_geofence',
    title: 'Delivered Within Geofence',
    width: 130,
  },
  {
    dataIndex: 'delivered_geofence_distance',
    key: 'delivered_geofence_distance',
    title: 'Delivered Geofence Distance',
    width: 130,
  },
  {
    dataIndex: 'rider_score',
    key: 'rider_score',
    title: 'Driver Score',
    width: 130,
  },
  {
    dataIndex: 'order_startbike_within_geofence',
    key: 'order_startbike_within_geofence',
    title: 'Start Bike Within Geofence',
    width: 130,
  },
  {
    dataIndex: 'order_reachedstore_within_geofence',
    key: 'order_reachedstore_within_geofence',
    title: 'Reached Store Within Geofence',
    width: 130,
  },
  {
    dataIndex: 'eta_delivered_mins',
    key: 'eta_delivered_mins',
    title: 'Eta Drive Time',
    width: 130,
  },
  {
    dataIndex: 'avg_eta_reachedstore_mins',
    key: 'avg_eta_reachedstore_mins',
    title: 'Eta Delivered to Reached Store',
    width: 130,
  },
  {
    dataIndex: 'left_at_store',
    key: 'left_at_store',
    title: 'Left At Store',
    width: 130,
  },
];
  export const store_distance_metrics = [
    { dataIndex : 'hub_code', key : 'hub_code', title : 'Store ID', width : 130},
    { dataIndex : 'hub_name', key : 'hub_name', title : 'Store Name', width : 130},
    { dataIndex : 'hub_order_count_no_exclude_filter', key : 'hub_order_count_no_exclude_filter', title : 'Total Orders', width : 130},
    { dataIndex : 'hub_excluded_count', key : 'hub_excluded_count', title : 'Excluded Orders', width : 130},
    { dataIndex : 'order_count', key : 'order_count', title : 'Orders Considered', width : 130},
    { dataIndex : 'hub_delivery_distance_travelled_km', key : 'hub_delivery_distance_travelled_km', title : 'Forward Distance Travelled (KMs)', width : 130},
    { dataIndex : 'hub_return_distance_travelled_km', key : 'hub_return_distance_travelled_km', title : 'Reverse Distance Travelled (KMs)', width : 130},
    { dataIndex : 'hub_total_distance_travelled_km', key : 'hub_total_distance_travelled_km', title : 'Total Distance (KMs)', width : 130},
    { dataIndex : 'hub_distance_per_order', key : 'hub_distance_per_order', title : 'Distance Per Order', width : 130},
  ];

  export const store_distance_exceptions = [
    { dataIndex : 'hub_code', key : 'hub_code', title : 'Store ID', width : 130},
    { dataIndex : 'hub_name', key : 'hub_name', title : 'Store Name', width : 130},
    { dataIndex : 'total_order_count', key : 'total_order_count', title : 'Orders Considered', width : 130},
    { dataIndex : 'distance_diff_gt_10_per', key : 'distance_diff_gt_10_per', title : '% Orders with Distance Diff. More than 10%', width : 130},
  ];

  export const rider_distance_exceptions = [
    { dataIndex : 'worker_code', key : 'worker_code', title : 'Rider Code', width : 130},
    { dataIndex : 'hub_code', key : 'hub_code', title : 'Store ID', width : 130},
    { dataIndex : 'total_order_count', key : 'total_order_count', title : 'Orders Considered', width : 130},
    { dataIndex : 'distance_diff_gt_10_per', key : 'distance_diff_gt_10_per', title : '% Orders with Distance Diff. More than 10%', width : 130},
  ];

  export const rider_checkin_checkout = [
    { dataIndex : 'worker_code', key : 'worker_code', title : 'Rider Code', width : 130},
    { dataIndex : 'checkin_time', key : 'checkin_time', title : 'Check In Time', width : 130},
    { dataIndex : 'checkout_time', key : 'checkout_time', title : 'Check Out Time', width : 130},
    { dataIndex : 'date', key : 'date', title : 'Date', width : 130},
    { dataIndex : 'delivered_count', key : 'delivered_count', title : 'Delivered Count', width : 130},
  ];

  export const store_incentive_table = [
    {
      dataIndex: 'hub_code',
      key: 'hub_code',
      title: 'Store Id',
      width: 130,
    },
    {
      dataIndex: 'delivered_count',
      key: 'delivered_count',
      title: 'Orders Considered',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_30',
      key: 'dot_lt_30',
      title: 'DOT% <30 min',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_29',
      key: 'dot_lt_29',
      title: 'DOT% <29 min',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_27',
      key: 'dot_lt_27',
      title: 'DOT% <27 min',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_25',
      key: 'dot_lt_25',
      title: 'DOT% <25 min',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_23',
      key: 'dot_lt_23',
      title: 'DOT% <23 min',
      width: 130,
    },
    {
      dataIndex: 'dot_lt_20',
      key: 'dot_lt_20',
      title: 'DOT% <20 min',
      width: 130,
    },
  ];

export const store_partially_completed = [
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'total_order_count',
    key: 'total_order_count',
    title: 'Total Orders',
    width: 130,
  },
  {
    dataIndex: 'completed_count',
    key: 'completed_count',
    title: 'Orders Completed',
    width: 130,
  },
  {
    dataIndex: 'partially_completed_count',
    key: 'partially_completed_count',
    title: 'Orders Partially Completed',
    width: 130,
  },
  {
    dataIndex: 'manually_unassigned_count',
    key: 'manually_unassigned_count',
    title: 'Manually Unassigned',
    width: 130,
  },
];

export const rider_missing_checkout = [
  {
    dataIndex: 'hub_code',
    key: 'hub_code',
    title: 'Store Id',
    width: 130,
  },
  {
    dataIndex: 'region',
    key: 'region',
    title: 'Region',
    width: 130,
  },
  {
    dataIndex: 'circle',
    key: 'circle',
    title: 'Circle',
    width: 130,
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    width: 130,
  },
  {
    dataIndex: 'hub_name',
    key: 'hub_name',
    title: 'Store Name',
    width: 130,
  },
  {
    dataIndex: 'worker_code',
    key: 'worker_code',
    title: 'Rider Code',
    width: 130,
  },
  {
    dataIndex: 'worker_name',
    key: 'worker_name',
    title: 'Rider Name',
    width: 130,
  },
  {
    dataIndex: 'worker_type',
    key: 'worker_type',
    title: 'Rider Type',
    width: 130,
  },
  {
    dataIndex: 'workdate',
    key: 'workdate',
    title: 'Business date',
    width: 130,
  },
];
export const notifications_history_columns = [
  { dataIndex : 'title', key : 'title', title: 'Title' },
  { dataIndex : 'created_at', key : 'created_at', title: 'Date Created' },
  { dataIndex : 'scheduled_at', key : 'scheduled_at', title: 'Date Scheduled' },
  { dataIndex : 'broadcasted_to', key : 'broadcasted_to', title: 'Broadcasted To' },
];
