import React, { useEffect } from 'react';
import { CssType, ThemeType} from '../../../theming/jssTypes';
import lodash from 'lodash';
import withStyles, { WithStylesProps } from 'react-jss';
import { Collapse, Modal, Progress, Tooltip, message } from 'antd';
import { CheckCircleFilled, CloseOutlined, ExclamationCircleFilled, CloseCircleFilled, CloudDownloadOutlined } from '@ant-design/icons';
import { createAndDownloadCSVFileFromJSON, promiseAllWithConcurrency } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
const { Panel } = Collapse;

const styles = (theme: ThemeType): CssType => {
    return {
        wrapper: {
            position: 'fixed',
            left: '24px',
            bottom: 0,
            zIndex: 1000,
        },
        collapsible: {
            width: '400px',
            '& .ant-collapse-content-box': {
                padding: 0,
                backgroundColor: '#FFFFFF',
            },
            '& .ant-collapse-header': {
                alignItems: 'center !important',
                padding: '12px 16px !important',
            },
            '& .ant-collapse-arrow': {
                color: '#FFFFFF',
                fontSize: '16px !important',
                marginRight: '20px !important',
            },
        },
        panel: {
            backgroundColor: '#0F1E37',
            alignItems: 'center',
        },
        header: {
            backgroundColor: '#0F1E37',
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        closeIcon: {
            color: '#FFFFFF',
        },
        progressData: {
            backgroundColor: '#F4F9FA',
            fontSize: '10px',
            color: '#333333',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '11px 16px',
        },
        progressMetrics: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        metricTile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '133px',
            paddingTop: '25px',
            paddingBottom: '25px',
        },
        metricNumber: {
            fontSize: '16px',
            color: '#111111',
        },
        metricStatus: {
            fontSize: '12px',
            color: '#333333',
        },
    };
}

interface GenericUploadStatusBarProps extends WithStylesProps<typeof styles>{
    heading: string,
    apiCallFn: (requestData: any) => Promise<any>,
    data: any[],
    getRequestBody: (batchData: any[]) => any;
    onCompletion: () => any;
    failureArrayKeysToShow: string[];
    isSingleApi?: boolean;
    onAllBatchesComplete?: () => Promise<any>;
    dataHeaders: any;
}

const GenericUploadStatusBar: React.FC<GenericUploadStatusBarProps> = (props) => {
    const { t } = useTranslation();
    const { classes, heading, data, getRequestBody, apiCallFn, onCompletion, failureArrayKeysToShow, dataHeaders = [] } = props;
    const [pendingCount, setPendingCount] = React.useState(data.length);
    const [successCount, setSuccessCount] = React.useState(0);
    const [failures, setFailures] = React.useState([]);
    const progressPercentage = pendingCount === 0 ? 100 : Math.floor((successCount + failures.length) / (data.length) * 100);
    const jobComplete = pendingCount === 0;

    useEffect(() => {
        // divide the data into batches and send them to the endpoint
        // if isSingleApi is true, send a single object in the request body
        // update the progress bar and the metrics based on the response
        const batchSize = props.isSingleApi ? 1 : 25;
        const concurrencyLimit = 3;
        const batchedData = lodash.chunk(data, batchSize);
        const promises = batchedData.map((batch, index) => {
            return (async () => {
                const dataForRequestBody = props.isSingleApi ? batch[0] : [...dataHeaders, ...batch];
                const requestBody = getRequestBody(dataForRequestBody);
                const response = await apiCallFn({
                    ...requestBody,
                    ...dataHeaders,
                    // for elastic search
                    bulkUploadData: {
                        batchNumber: index + 1,
                        totalBatches: batchedData.length,
                    },
                });
                let success;
                let failuresData;
                if (response.isSuccess) {
                    failuresData = lodash.get(response, 'data.failures', []);
                    success = batch.length - failuresData.length;
                } else {
                    failuresData = lodash.get(response, 'data.failures', batch.map((data, dataIdx) => {
                        const toRet = {
                            ...data,
                            rowNum: (index * batchSize) + dataIdx + 1,
                            message: response.errorMessage || 'Something went wrong',
                            reason: response.errorMessage || 'Something went wrong',
                        };

                        return toRet;
                    }));
                    success = 0;
                }
                setSuccessCount(prevCount => prevCount + success);
                setFailures(prevFailures => prevFailures.concat(failuresData));
                setPendingCount(prevCount => prevCount - batch.length);
            })();
        });
    
        promiseAllWithConcurrency(promises, concurrencyLimit)
            .then(() => {
                if (props.onAllBatchesComplete) {
                    props.onAllBatchesComplete();
                }
            })
            .catch(error => {
                console.error('Error processing batches:', error);
            });
    }, []);

    useEffect(() => {
        if (jobComplete) {
            message.success(t('The operation is complete. You may download the failed results if any by clicking the download icon. To see your changes, refresh the data.'));
        }
        return () => {};
    }, [jobComplete]);

    const renderHeader = () => {
        return <div className={classes.header}>
            <Progress type="circle" percent={progressPercentage} showInfo={false} width={24} strokeWidth={14} />
            <span style={{ marginLeft: '12px' }}>{t(heading)}</span>
        </div>;
    };

    const renderMetric = (status: string, icon: any, count: number) => {
        return <div className={classes.metricTile}>
            <div className={classes.metricNumber}>{count}</div>
            <div className={classes.metricStatus}>
                {icon}
                <span style={{ marginLeft: '3px' }}>{t(status)}</span>
            </div>
        </div>;
    };

    const downloadFailures = () => {
        if (!jobComplete) return;
        createAndDownloadCSVFileFromJSON(failures, 'failure-data-' + new Date().toISOString(), failureArrayKeysToShow);
    };

    const renderContent = () => {
        return <div>
            <div className={classes.progressData}>
                <span>{progressPercentage}% {t('Completed')}</span>
                <Tooltip title={t('Download failed results')}>
                    <CloudDownloadOutlined onClick={downloadFailures} style={{
                        fontSize: '16px',
                        color: jobComplete ? '#333333' : '#F4F9FA',
                        cursor: jobComplete ? 'pointer' : 'not-allowed',
                    }}/>
                </Tooltip>
            </div>
            <div className={classes.progressMetrics}>
                {renderMetric('Pending', <ExclamationCircleFilled style={{
                    color: '#F0A105',
                }} /> , pendingCount)}
                {renderMetric('Successful', <CheckCircleFilled style={{
                    color: '#20B249',
                }} /> , successCount)}
                {renderMetric('Failed', <CloseCircleFilled style={{
                    color: '#D40B00',
                }} /> , failures.length)}
            </div>
        </div>;
    };

    return (
        <div className={classes.wrapper}>
            <Collapse
                defaultActiveKey={['1']}
                expandIconPosition="right"
                className={classes.collapsible}
            >
                <Panel
                    className={classes.panel}
                    header={renderHeader()}
                    key="1"
                    extra={<CloseOutlined className={classes.closeIcon} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (jobComplete) {
                            onCompletion();
                        } else {
                            Modal.confirm({
                                title: t('Attention'),
                                content: t('If you move away, the process will be incomplete. Are you sure you want to proceed ?'),
                                okText: t('Proceed'),
                                cancelText: t('Dont Proceed'),
                                width: 500,
                                onOk: () => onCompletion(),
                                okType: 'danger',
                            });
                        }
                    }}/>}
                    showArrow={true}
                >
                    {renderContent()}
                </Panel>
            </Collapse>
        </div>
    );
};

export default withStyles(styles, { injectTheme: true })(GenericUploadStatusBar);
